* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--bg);
  overflow: hidden;
}

iframe {
  pointer-events: none;
}

@primary-color: #1DA57A;