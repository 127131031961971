.Select-input {
  width: 200px;
}

.ant-select:not(.ant-select-input) .ant-select-selector {
  background-color: var(--stroke) !important;
  border-radius: 8px;
  border: 1px solid var(--formElementBorder);
  font-size: 14px;
  font-weight: 500;
  color: var(--statsLabel) !important;
  align-items: center;
  height: 45px !important;
  width: 135px;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-input)
  .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}
.ant-select-arrow {
  // left: 120px;
  color: var(--statsLabel);
  height: 3.5px;
  width: 6px;
  font-size: 8px;
  margin-top: -3.5px;
}
.my-select-input {
  margin-top: 10px;
  label {
    color: #b1b1b8;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.2px;
    font-size: 13px;
    line-height: 34px;
  }
}
.ant-select-dropdown {
  border-radius: 8px;
}
.ant-select-multiple .ant-select-selection-item {
  background: var(--borderColor);
  border-radius: 16px;
}
@media (max-width: 475px) {
  .ant-select {
    width: 100%;
  }
}

@primary-color: #1DA57A;