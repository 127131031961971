.analitics-info {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;

  .outter {
    max-width: calc(50% - 10px);
  }
}

@media screen and (max-width: 1190px) {
  .analitics-info {
    flex-direction: column;

    .outter {
      max-width: 100%;
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
}

@primary-color: #1DA57A;