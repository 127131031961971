.ant-picker {
  background-color: var(--stroke) !important;
  border: 1px solid var(--formElementBorder) !important;
  border-radius: 14px;
  height: 45px;
  max-width: 719px;
  width: 100%;

  &.ant-picker-focused {
    box-shadow: none;
  }
  .ant-picker-active-bar {
    background: var(--main);
  }
  .ant-picker-range-separator {
    padding: 0 30px 0 0;
  }
  .ant-picker-clear {
    background-color: var(--stroke);
  }
}
.ant-picker-range-wrapper {
  justify-content: center;
  position: absolute;
  .ant-picker-range-arrow {
    margin-left: 85px;
    top: -5px;
  }
}
.ant-picker-panel-container {
  .ant-picker-panels {
    flex-direction: column;
  }
}

@primary-color: #1DA57A;