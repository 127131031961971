.ant-form{
    width: 100%;
    .ant-form-item{
        margin-bottom: 15px;
    }
}
.ant-form-item-control-input-content{  
    input{
        border-radius: 14px;
        background-color: var(--stroke);
        height: 50px;
        &:hover, &:focus{
            border: 1px solid #d9d9d9;
            background-color: var(--white);
            box-shadow: none;
        }
    }
}

.ant-input-affix-wrapper{
    border-radius: 14px !important;
    background-color: var(--stroke) !important ;
    padding: 0 11px !important;
    height: 50px;
    display: flex;
    align-items: center;
    &:hover, &:focus, &:focus-visible{
        border: 1px solid #d9d9d9 !important;
        background-color: var(--white) !important;
        box-shadow: none !important;
        outline: none;
    }
    input{
        height: 48px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding-left: 10px !important;
        &:hover, &:focus{
            border: none;
        }
    }
    .ant-input-suffix{
        z-index: 1;
        position: absolute;
        right: 10px;
    }
}
.ant-input-affix-wrapper-focused{
    box-shadow: none;
    border-color: #d9d9d9; 
}

@primary-color: #1DA57A;