.navigation {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--white);
  padding: 20px;
  border-top: 0.5px solid rgba(255, 255, 255, 0.1);
  z-index: 2;
  padding: 0 45px;
  .navItem__label {
    display: none;
  }
}

@media (max-width: 767px) {
  .aside-logo {
    display: none;
  }
  .navigation {
    background-color: var(--white);
    .navItem__label {
      display: none;
    }
  }
}
@media screen and (min-width: 768px) {
  .sidenav-wrapper {
    width: 90px;
    height: 100vh;
    background-color: var(--white);
  }
  .site-layout {
    overflow: hidden;
  }
  .aside-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 20px;

    img {
      height: 40px;
    }
  }
  .navigation {
    position: unset;
    height: 100%;
    min-width: 80px;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    padding: 40px 0 0;
    align-items: center;
    .navItem {
      display: flex;
      flex-direction: column;
      height: 50px;
      align-items: center;
      margin-bottom: 50px;
      &__label {
        display: flex;
        color: var(--leftMenuText);
        font-weight: 500;
      }
      &__icon {
        display: flex;
      }
      &:hover {
        border-radius: 14px;
      }
    }
  }
}

@primary-color: #1DA57A;