.contentHolder {
  padding: 15px;
  margin-bottom: 140px;
}
@media (min-width: 768px) {
  .contentHolder {
    padding: 15px 35px;
  }
}

@media screen and (max-width: 479px) {
  .contentHolder {
    padding-top: 85px;
  }
}

@primary-color: #1DA57A;