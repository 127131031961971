.filterDesktop {
  border-left: 1px solid var(--stroke);
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  max-width: 300px;
  height: 100vh;
  overflow: auto;
  width: 300px;

  .ant-btn-primary {
    margin-top: 20px;
  }
}

@primary-color: #1DA57A;