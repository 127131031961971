.doughnut-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  .doughnut-chart-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    .amount-wrapper {
      margin-bottom: 10px;
    }
    .label-wrapper {
      margin-bottom: 0;
    }
    .value-name {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
      margin-top: 1px;
      background-color: var(--chartColor2);
    }
    .value-name-color {
      background-color: var(--main);
    }
    span {
      font-weight: 700px;
      font-size: 20px;
    }
    .percent-value {
      font-weight: 600;
      font-size: 10px;
      margin-left: 5px;
    }
  }
  .doughnut-chart {
    width: 50%;
    display: flex;
    align-items: center;
    margin-top: -11px;
  }
}
.ant-empty {
  width: 100%;
}
@media (min-width: 991px) {
  .doughnut-wrapper {
    // flex-direction: column-reverse !important;
    .doughnut-chart-info {
      // margin-top: 50px;
      // width: 100% !important;
      display: flex;
      // flex-direction: row !important;
      // justify-content: space-evenly;
      .amount-wrapper {
        margin-bottom: 0;
      }
      .statsCard {
        &__amountHolder {
          &--amount {
            flex: none;
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;