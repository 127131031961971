.filters {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--stroke);
  .outter {
    margin-bottom: 0;
    &__inner {
      border-radius: 0;
    }
  }
  .filters-wrapper {
    width: 100%;
  }

  &__appMode {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &--calendar {
      display: flex;
      align-items: center;

      &--date {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: var(--text);
        margin-left: 13px;
        .date-wrapper {
          color: var(--statsLabel);
        }

        .previous {
          font-weight: normal;
          font-size: 11px;
          line-height: 11px;
          color: var(--statsLabel);
          margin-top: 3px;
        }
      }
    }
    &--filters {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
  }

  &__forms {
    display: none;
  }
}
.ant-drawer-title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
    .ant-drawer-content {
      border-radius: 35px 35px 0 0;
      .ant-drawer-title {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.ant-drawer-body {
  .ant-btn {
    width: calc(100% - 48px);
    max-width: 717px;
    position: absolute;
    bottom: 30px;
  }
}
.Select-input {
  width: 100%;
}

.ant-select:not(.ant-select-input) .ant-select-selector {
  max-width: 719px;
  min-width: 135px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .filters {
    position: sticky;
    top: 0;
    z-index: 1;
    margin: 0 35px;
    border-radius: 25px;
    border-top: none;
    .outter {
      &__inner {
        border-radius: 25px !important ;
      }
    }

    &__forms {
      display: flex;
    }
  }
}

.textWrapper {
  display: flex;
  flex-wrap: wrap;
  // padding: 0 15px;
}
.textFilter {
  display: flex;
  align-self: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  .textInfo {
    height: 35px;
    width: fit-content;
    border-radius: 50px;
    background-color: var(--main);
    color: var(--white);
    padding: 0 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.close {
  position: relative;
  opacity: 0.8;
  margin-top: -22px;
  margin-right: 11px;
  cursor: pointer;
  margin-left: 10px;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  top: 6px;
  left: 6.5px;
  content: ' ';
  height: 10px;
  width: 2px;
  background-color: var(--white);
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

@media screen and (max-width: 479px) {
  .filters {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 10;
  }
}

@primary-color: #1DA57A;