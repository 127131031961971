.ant-layout-content {
  background-color: var(--bg);
}
.chart {
  height: 20px;
}
.ant-picker {
  margin-top: 15px;
}
.ant-list-header {
  padding: 15px 0 0 0;
  border-bottom: none !important;
  .order-list-label {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    color: var(--statsLabel);
    // .header-label-0 {
    //   width: 100%;
    //   text-align: right;
    // }
    .header-label-0{
      width: 50%;
    }
    .header-label-1 {
      // min-width: 118px;
      width: 20%;
      text-align: right;
    }
    .header-label-2{
      width: 30%;
      text-align: right;
    }
  }
}

.chart-info {
  display: flex;
  margin-bottom: 10px;
  .value-name {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    margin-top: 1px;
    background-color: var(--chartColor2);
  }
  .value-name-color {
    background-color: var(--chartColor1);
  }
  .value-name-color3 {
    background-color: var(--chartColor3);
  }
}
.analyticsInfo {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  // .outter-width{
  //   max-width: 600px;
  // }
  .outter {
    max-width: calc(50% - 10px);
  }
}
.chart-info {
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  .analyticsInfo {
    flex-direction: column !important;
    gap: 0px !important;

    .outter {
      max-width: 100%;
    }
  }
}

@primary-color: #1DA57A;