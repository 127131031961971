.ant-btn:not(ant-btn) {
  border-radius: 14px;
  border: none;
  background-color: var(--btn);
  height: 50px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all.5s linear !important;
  &:hover {
    background-color: var(--btnHover);
    color: var(--white);
    transition: all.5s linear !important;
  }
  &:focus {
    box-shadow: none;
    outline: none !important;
  }
}
.ant-btn-primary:not(ant-btn-primary) {
  background: var(--mainGradient);
  color: var(--white);
  &:hover {
    background-color: var(--btnPrimaryHover);
    color: var(--white);
  }
}

@primary-color: #1DA57A;