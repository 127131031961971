.mobile-app-list {
  &__wrapper {
    width: 335px;
    margin-top: 10px;
    background-color: var(--white);
    padding: 25px;
    border-radius: 30px;

    &--title {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 500;
      color: var(--text);
      text-align: center;
    }

    &--badges {
      display: flex;
      justify-content: space-between;
      &--withQR {
        display: flex;
        justify-content: space-between;
        img {
          height: 130px;
          margin-bottom: 5px;
        }
      }
      img {
        width: 130px;
      }
    }
  }
}

@primary-color: #1DA57A;