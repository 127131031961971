.main {
  display: flex;
  flex-direction: column;
}

.site-layout-background {
  overflow-y: auto;
  height: 100vh;
}

@media screen and (min-width: 768px) {
  .main {
    flex-direction: row;
    .filterAndContentWrapper {
      display: flex;
    }
  }
}

@media screen and (max-width: 768px) {
  .site-layout-background {
    height: calc(100vh - 60px);
  }
}

@primary-color: #1DA57A;