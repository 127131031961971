.customer-block-vertical {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  & + .customer-block-vertical {
    border-top: 1px solid var(--borderColor);
  }
  h5 {
    font-size: 14px;
    font-weight: 700;
  }
  .name-wrapper {
    margin-right: 10px;
    h5 {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
.order-list-label {
  display: flex;
  justify-content: space-between;
}

@primary-color: #1DA57A;