.page-type-edit {
  flex-direction: column;

  .login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--white);
    padding: 25px;
    border-radius: 30px;
    width: 335px;
    .icon {
      margin-bottom: 20px;
    }
    .ant-btn {
      width: 100%;
      margin-top: 35px;
    }
    .newUserWrapper {
      margin-top: 20px;
      font-size: 12px;
      :first-child {
        margin-right: 7px;
      }
      a {
        color: var(--main);
      }
    }
  }
  .aragich-logo {
    height: 50px;
    margin-bottom: 20px;
  }
}

@primary-color: #1DA57A;