.appList {
  overflow-y: auto;
  flex-wrap: wrap;
  width: 100%;
  .order-list-card {
    justify-content: space-between;
    padding: 4px 0;
    border-bottom: 1px solid var(--borderColor);
    overflow: hidden;
    &:last-child {
      border-bottom: none;
    }
    .order-list-wrapper {
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      width: 100%;
      .order-list-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
      }
      .order-list-info {
        justify-content: flex-end;
      }
      h1 {
        margin-bottom: 0;
        font-size: 12px;
        white-space: break-spaces;
        margin-right: 10px;
      }
      .content-info {
        display: flex;
        flex-direction: column;
        // min-width: 120px;
        width: 40%;
        & + .content-info {
          margin-left: 10px;
        }
      }
      .payment-info{
        width: 60%;
      }
      h5 {
        margin-bottom: 0;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        text-align: right;
      }
      .compareValue {
        text-align: right;
      }
    }
  }
}
@media (max-width: 490px) {
  .appList {
    .order-list-card {
      .order-list-wrapper {
        .content-info {
          // min-width: 100px;
          align-items: flex-end;
        }
      }
    }
  }
  // .ant-list-header .order-list-label .header-label-1 {
    // min-width: 115px !important;
  // }
}

@primary-color: #1DA57A;