.navItem {
  font-size: 14px;
  &__icon {
    svg {
      path {
        fill: var(--gray);
      }
    }
  }
  &__label {
    margin-top: 4px;
  }

  &.active {
    .navItem {
      &__icon {
        svg {
          path {
            fill: var(--main);
          }
        }
      }
      &__label {
        color: var(--main);
      }
    }
  }
}
@media (min-width: 768px) {
  .navItem {
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bg);
      border-radius: 16px;
      min-height: 54px;
      width: 54px;
      border: 2px solid var(--bg);
    }

    &.active {
      .navItem {
        &__icon {
          border: 2px solid var(--main);
        }
      }
    }
  }
}

@primary-color: #1DA57A;