.statsCard {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &--text {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: var(--statsTitle);
    }
    &--icon {
      margin-right: 7px;
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        height: 25px;
        // path {
        //   fill: var(--statsTitle);
        // }
      }
    }
  }
  &__amountHolder {
    display: flex;
    gap: 10px;
    &--amount {
      flex: 1;
      &--label {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: var(--statsLabel);
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        margin-right: 15px;
      }
      &--value {
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        color: var(--text);
      }
    }
  }
}

@primary-color: #1DA57A;