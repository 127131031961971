.appHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background: var(--headerBg);
  height: 60px;
  &__pageTitle {
    font-size: 20px;
    font-weight: 600;
  }
  &__userDropdown {
    &--btn {
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      .ant-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f3f4f8;
        height: 31px;
        width: 31px;
      }
    }
  }

  .setting-icon {
    display: flex;
    align-items: center;
    width: 33px;
    svg {
      fill: #a7a6b9;
      cursor: pointer;
      width: 26px;
      height: 26px;
      margin-left: 1px;
    }
    &:hover {
      background-color: rgba(167, 166, 185, 0.1);
      width: 39px;
      height: 39px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-left: 1px;
      }
    }
  }
}
@media (min-width: 768px) {
  .appHeader {
    padding: 0 35px;
    background-color: var(--bg);
  }
}

@primary-color: #1DA57A;