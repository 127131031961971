.settingsPage {
  &__title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  .settingsItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    &--label {
      text-transform: uppercase;
      font-size: 13px;
      color: var(--label);
      font-weight: 600;
    }
    .my-select-input {
      margin-top: 0;
    }
    &.logoutWrapper {
      display: flex;
      justify-content: center;
      padding: 20px;
      .logout-link {
        color: var(--red);
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        svg {
          margin-right: 6px;
        }
      }
    }
    & + .settingsItem {
      border-top: 1px solid var(--borderColor);
    }
  }
}

@media screen and (max-width: 768px) {
  .settingsPage {
    padding: 25px 0;
    .outter__inner {
      border-radius: 0;
    }
  }

  .contentHolder.settingsContentHolder {
    padding: 0;
  }
}

@primary-color: #1DA57A;