.list-vertical{
    overflow-y: auto;
    flex-wrap: wrap ;
    width: 70%;
}
.order-list-card{
display: flex;
flex-direction: row;
justify-content: space-around;
.country-flag{
    margin-right: 10px;
    width: 18px;
    height: 11px;
    display: flex;
    align-items: center;
    svg{
        width: 100%;
    }
    .aragich-flag{
        border-radius: 2px;
        width: 100%;
        height: 11px;
        display: flex;
    }
}
}


@primary-color: #1DA57A;