.outter {
  flex: 1;
  margin-bottom: 20px;

  &__inner {
    height: fit-content;
    background-color: var(--outterBg);
    border-radius: 25px;
    padding: 20px;
    .outter-title {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      color: var(--leftMenuText);
    }
  }
}

@primary-color: #1DA57A;