.compareValue {
  font-size: 12px;
  font-weight: 400;
  color: var(--label);
  &__positive {
    color: var(--green);
  }
  &__negative {
    color: var(--red);
  }
}

@primary-color: #1DA57A;